import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/builds/everyone/dcdcweb-saturday/src/components/Layout/index.js";
import Form from 'react-jsonschema-form';
export const schema = {
  title: "Todo",
  type: "object",
  required: ["title"],
  properties: {
    title: {
      type: "string",
      title: "Title",
      default: "A new task"
    },
    done: {
      type: "boolean",
      title: "Done?",
      default: false
    }
  }
};
const layoutProps = {
  schema
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>



      <MDXTag name="p" components={components}><a className="gatsby-resp-image-link" href="/static/3c6f7161ca5a5df56ff26b632b93c702/a8e30/coophack-2019-8-64-70.png" style={{
          "display": "block"
        }} target="_blank" rel="noopener">
    <span className="gatsby-resp-image-wrapper" style={{
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1336px"
          }}>
    <span className="gatsby-resp-image-background-image" style={{
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0px",
              "left": "0px",
              "backgroundSize": "cover",
              "display": "block"
            }}></span>
    <img className="gatsby-resp-image-image" style={{
              "width": "100%",
              "height": "100%",
              "margin": "0px",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0px",
              "left": "0px",
              "boxShadow": "inset 0px 0px 0px 400px white"
            }} alt="coophack 2019 8 64 70" title="" src="/static/3c6f7161ca5a5df56ff26b632b93c702/514d5/coophack-2019-8-64-70.png" srcSet="/static/3c6f7161ca5a5df56ff26b632b93c702/fe72e/coophack-2019-8-64-70.png 334w,
/static/3c6f7161ca5a5df56ff26b632b93c702/70b80/coophack-2019-8-64-70.png 668w,
/static/3c6f7161ca5a5df56ff26b632b93c702/514d5/coophack-2019-8-64-70.png 1336w,
/static/3c6f7161ca5a5df56ff26b632b93c702/dd69c/coophack-2019-8-64-70.png 2004w,
/static/3c6f7161ca5a5df56ff26b632b93c702/a8e30/coophack-2019-8-64-70.png 2160w" sizes="(max-width: 1336px) 100vw, 1336px" />
  </span>
  </a></MDXTag>
      <MDXTag name="h1" components={components}>{`Join us for Coop Hack!`}</MDXTag>
      <MDXTag name="h4" components={components}>{`Part of Cooperative Fortnight 2019`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Update 1/7/2019:`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`Due to lower than expected numbers, we have decided to make the event a free-of-charge open door hackalong. This doesn't change things significantly, except that there will no longer be an official competition element to proceedings so instead you can expect things to feel much more like an open door co-working space.`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="em" components={components} parentName="p">{`As well as being there for you, we will be there working on our own projects too. So even if you're just curious to see what we're up to, you can pop in at any point throughout the event.`}</MDXTag></MDXTag>
      <MDXTag name="hr" components={components}></MDXTag>
      <MDXTag name="h3" components={components}>{`When: July 5th - July 6th`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Where: `}<MDXTag name="a" components={components} parentName="h3" props={{
          "href": "https://www.middlefloor.org/"
        }}>{`The Middle Floor, Leeds`}</MDXTag></MDXTag>
      <MDXTag name="h3" components={components}>{`Tickets: `}<MDXTag name="a" components={components} parentName="h3" props={{
          "href": "https://www.eventbrite.co.uk/e/coop-hack-tickets-62261546963"
        }}>{`Available on EventBrite!`}</MDXTag></MDXTag>
      <MDXTag name="h3" components={components}>{`Themes and Topics: Cooperative Tech`}</MDXTag>
      <MDXTag name="p" components={components}>{`From ride sharing to social networks to blockchains to cloud computing. There is a limitless spectrum of privately owned and operated technology platforms, much of which it seems we can't live without. DCDC invite collaborators to come together to turn it all off and on again and ask the (rhetorical) question, isn't it time we do this differently?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Whether you have a plan to make recycling work, keep the high street buzzing, fix gig work or give the vulnerable access to tech. However big or small your idea, we want to see it happen and we're happy to help.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Details`}</MDXTag>
      <MDXTag name="p" components={components}>{`Teams will ideally be between 3-6 people and you can come as a group or alone and find an interesting project to team up on at the event. Projects will benefit from a range of people, skills and experience; everyone is valuable. Also don't worry if you're not technical, the DCDC team will be on hand throughout the event to provide technical insight wherever we can and talk through ideas.`}</MDXTag>
      <MDXTag name="p" components={components}>{`We're not prescriptive about the tech you'll use to bring your ideas to life and we're entertaining any and all project ideas providing they fit the remit of cooperative/ethical tech. Be sure to check out our seed ideas towards the end of the event page for a little inspiration to get those cogs turning. We expect most projects to be software based, but you can of course try hardware ideas too. If you are planning something elaborate remember that we're only together for the two days (and night if you can handle it!) and the space is quite cosy.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Our judges hail from various areas of the tech and co-op scenes and projects are going to be judged primarily against their alignment to the cooperative values of `}<MDXTag name="strong" components={components} parentName="p">{`self-help, self-responsibility, democracy, equality, equity, and solidarity,`}</MDXTag>{` along with their completedness. You can read more about cooperative values and principles at `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.ica.coop/en/cooperatives/cooperative-identity#cooperative-principles"
        }}>{`the ICA website.`}</MDXTag>{` `}</MDXTag>
      <MDXTag name="p" components={components}>{`Prizes! Everyone loves them. We've got them.`}</MDXTag>
      <MDXTag name="p" components={components}>{`To help, we've made a quick attendee checklist and provided the planned agenda.`}</MDXTag>
      <MDXTag name="h4" components={components}>{`Attendee Checklist`}</MDXTag>
      <MDXTag name="p" components={components}>{`You should bring:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`You and your can do attitude!`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`You can bring:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`A laptop or tablet, if you think you'll need it`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Important project gadgets (a Raspberry Pi for example), if you think you'll need any`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`A sleeping bag if you plan to stay inside the venue overnight`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Your none-electronic tools (such as art materials/supplies), if you think you'll need any`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`As many great ideas as you like `}</MDXTag>
      </MDXTag>
      <MDXTag name="h4" components={components}>{`Agenda`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Friday`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`10:00 - Breakfast, Introduction & Team Creation`}</MDXTag>
      <MDXTag name="p" components={components}>{`13:30 - Lunch`}</MDXTag>
      <MDXTag name="p" components={components}>{`18:00 - Dinner`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Saturday`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`09:00 - Breakfast`}</MDXTag>
      <MDXTag name="p" components={components}>{`12:30 - Lunch`}</MDXTag>
      <MDXTag name="p" components={components}>{`15:00 - Show & Tells`}</MDXTag>
      <MDXTag name="p" components={components}>{`16:00 - Judging & Retrospective`}</MDXTag>
      <MDXTag name="h4" components={components}>{`Questions`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li">{`Can we work overnight?`}</MDXTag>
          <MDXTag name="p" components={components} parentName="li">{`Yes you can and you may. We know some people will want to stay up all night to work on their projects and this is okay, but consider bringing a sleeping bag or similar articles if you have nowhere else to rest on Friday night.`}</MDXTag>
          <MDXTag name="p" components={components} parentName="li">{`Bear in mind we are working above a lively bar and it might not be a quiet night if you do need to get some sleep.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li">{`Do we need to bring food?`}</MDXTag>
          <MDXTag name="p" components={components} parentName="li">{`We are providing adequate food on both days of the event. You are welcome to make any alternative arrangement you wish to, but we may not allow you to eat food you bring yourself inside the event in case foods contain allergens that put other participants at risk.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li">{`I have specific dietary requirements. Will the food provided be suitable?`}</MDXTag>
          <MDXTag name="p" components={components} parentName="li">{`We will do whatever we can to make sure that any participant's dietary requirements are met. If you have specific requirements, please email us to let us know via `}<MDXTag name="a" components={components} parentName="p" props={{
              "href": "mailto:team@dcdc.io"
            }}>{`team@dcdc.io`}</MDXTag>{` or the relevant link on the eventbrite ticket page linked below.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li">{`How accessible is the venue?`}</MDXTag>
          <MDXTag name="p" components={components} parentName="li">{`Access to the venue requires use of an exterior steel staircase. If you wish to participate in this event and venue access is a problem for you, please get in touch via `}<MDXTag name="a" components={components} parentName="p" props={{
              "href": "mailto:team@dcdc.io"
            }}>{`team@dcdc.io`}</MDXTag>{` or the relevant link on the eventbrite ticket page linked below so that we can arrange your digital attendance free of charge.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li">{`Does the venue have parking?`}</MDXTag>
          <MDXTag name="p" components={components} parentName="li">{`No. There are numerous paid car parks close by.`}</MDXTag>
        </MDXTag>
        <MDXTag name="li" components={components} parentName="ul">
          <MDXTag name="p" components={components} parentName="li">{`Can I bring a certain piece of equipment?`}</MDXTag>
          <MDXTag name="p" components={components} parentName="li">{`Most people will bring a laptop or nothing at all and as a general rule we ask that you keep the gear you bring to a minimum. However if you simply must bring anything extracurricular, do so with the venue/your fellow hackers in mind and if unsure drop us an email via `}<MDXTag name="a" components={components} parentName="p" props={{
              "href": "mailto:team@dcdc.io"
            }}>{`team@dcdc.io`}</MDXTag>{`. Also, remember that neither the venue nor DCDC can accept any responsibility for any of the personal property that you choose to bring to the event .`}</MDXTag>
        </MDXTag>
      </MDXTag>
      <MDXTag name="h4" components={components}>{`Seed Ideas`}</MDXTag>
      <MDXTag name="h5" components={components}>{`Sharing Economy Apps`}</MDXTag>
      <MDXTag name="p" components={components}>{`So called sharing economy apps are a good target for #coophack. We don't have to name any names. We're all familiar with the apps that let us book taxis, order food, or find a place to crash for a few days. For location based apps, there are great open source projects like the `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://github.com/mapsme/omim"
        }}>{`maps.me`}</MDXTag>{` that can be used as a foundation.`}</MDXTag>
      <MDXTag name="h5" components={components}>{`Digital Democracy`}</MDXTag>
      <MDXTag name="p" components={components}>{`There are some great open source projects to build on in the digital democracy space; including `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://github.com/DemocracyOS/democracyos"
        }}>{`Democracy OS`}</MDXTag>{` and `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://github.com/citizenos/citizenos-api/blob/master/docs/index.md"
        }}>{`Citizen OS`}</MDXTag>{`. `}</MDXTag>
      <MDXTag name="h5" components={components}>{`Decentralised/Distributed Apps`}</MDXTag>
      <MDXTag name="p" components={components}>{`dApps are an emerging area, but they promise to fundamentally change the infrastructure of the world wide web. The internet has always been arguably decentralised and distributed but the world wide web and the apps and services we depend on have become increasingly centralised into tech monopolies. Search, social networking, and selling at auction are natural digital monopolies. What can technology like `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://ipfs.io/"
        }}>{`IPFS`}</MDXTag>{` and bitcoin (`}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://bitcoincore.org/"
        }}>{`eg: bitcoin core`}</MDXTag>{`) do to enable time banking or recreate the community bulletin boards and classified ads of old in the internet world?`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Sponsorship`}</MDXTag>
      <MDXTag name="p" components={components}>{`If you are interested in sponsoring Coop Hack! we'd love to hear from you. We don't have any formal sponsorship packages so we don't have any expectations. Please get in touch if you would like to offer your support and we'll be happy to discuss.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    